import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() url: string;
  constructor(private router: Router) { }

  ngOnInit() { }
  onBack() {
    this.router.navigate([this.url]);
  }
}
